import { useContext } from "react";
import { DarkModeContext } from "../../Context/DarkMode";
import ModalPopUp from "../Modals/Index";

const ContactMe = () => {
    const {darkMode} = useContext(DarkModeContext)
    return (
        <div className='contact-me'>
        <p>Please do not hesitate to reach out!</p>
            <div className={`about-me-links ${darkMode ? "dark" : "light"}`}>
                <a href="mailto:cooperadina@gmail.com?subject='Love your work!'&body='Really enjoyed looking at your website. Would love to set up a time to talk.'">email</a>
                <a href="https://www.linkedin.com/in/adina-cooper/">LinkedIn</a>
                <a href='https://angel.co/u/adina-cooper-1'>AngelList</a>
                <a href="https://github.com/arcoop">GitHub</a>
            </div>
            <ModalPopUp type="about-me-projects"/>
     </div>
    )
}

export default ContactMe;