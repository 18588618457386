import { useState } from "react"
import { Modal } from "../../Context/Modal"
import AboutMe from "./AboutMe"
import './ModalIndex.css'
import StickyNote from "../StickyNote/StickyNote"
import ViewMore from "./ViewMore"
import EmailMeForm from "./EmailMe"
import MyProjects from "./MyProjects"
import { useContext } from "react"
import { DarkModeContext } from "../../Context/DarkMode"

const ModalPopUp = ({type}) => {
    const [showModal, setShowModal] = useState(false)
    const {darkMode} = useContext(DarkModeContext)

    const handleClose = () => {
        setShowModal(false)
        document.body.style.overflow = "unset"
    }

    const handleClick = () => {
        setShowModal(true)
        document.body.style.overflow = "hidden"
    }

    let modal;
    if (type === "about-me") modal = <AboutMe closeModal={handleClose}/>
    if (type === "view-more" || type === "view-more-icon") modal = <ViewMore closeModal={handleClose}/>
    if (type === "email-me") modal = <EmailMeForm /> 
    if (type === "my-projects" || type === "my-projects-icon" || type === "about-me-projects") modal = <MyProjects closeModal={handleClose}/>

    let buttonText; 
    if (type === "about-me") buttonText = <StickyNote type={"intro"}/> 
    if (type === "view-more") buttonText = "View more"
    if (type === "my-projects") buttonText = "My projects"
    if (type === "email-me") buttonText = "Email me"
    if (type === "my-projects-icon") buttonText = <i className="fa-regular fa-folder-open"></i>
    if (type === "view-more-icon") buttonText = <i className="fa-solid fa-binoculars"></i>
    if (type === "about-me-projects") buttonText = "My projects"
    
    return (
        <>
            <div className={`modal-button ${type} ${darkMode ? "dark-hover" : "light-hover"}`} onClick={handleClick}>{buttonText}</div>
            {showModal &&
                <Modal onClose={handleClose} type={type}>
                    {modal}
                </Modal>
            }
        </>
    )
}

export default ModalPopUp;