import { useContext } from "react";
import MainPage from "./Components/MainPage/MainPage";
import { DarkModeContext } from "./Context/DarkMode";

function App() {
  const {darkMode} = useContext(DarkModeContext)

  return (
    <MainPage></MainPage>
    
  );
}

export default App;
