const ProjectsComponent = () => {
    return (
        <div className='project-sticky-caption'>
            <div className='project'>
                <div className='project-first-div'>
                    <a target="_blank" href="https://sparkle-sporcle.herokuapp.com/" className='project-image sparkle'></a>
                    <div className='technologies'>
                        <p>Javascript</p>
                        <div className='separator'><i className="fa-solid fa-circle"></i></div>
                        <p>Ruby Rails</p>
                        <div className='separator'><i className="fa-solid fa-circle"></i></div>
                        <p>React.js</p>
                        <div className='separator'><i className="fa-solid fa-circle"></i></div>
                        <p>Redux</p>
                    </div>
                </div>
            <div className='project-caption'>
                <div>A full-stack clone of the popular trivia games site, Sporcle.</div>
            </div>
            <div className='project-links'>
                <div><a target='_blank' href="https://sparkle-sporcle.herokuapp.com/">Live Link</a></div>
                <div><a target='_blank' href="https://github.com/arcoop/Sparkle">Github</a></div>
            </div>
        </div>

        <div className='project'>
            <div className='project-first-div'>
                <a target="_blank" href='https://arcoop.github.io/Bang/' className='project-image bang'></a>
                <div className='technologies'>
                    <p>Vanilla Javascript</p>
                    <div className='separator'><i className="fa-solid fa-circle"></i></div>
                    <p>Canvas</p>
                    <div className='separator'><i className="fa-solid fa-circle"></i></div>
                    <p>HTML</p>
                </div>
            </div>
            <div className='project-caption'>
                <div>I built this app solely in Vanilla Javacript and Canvas. It is based on one of my favorite card games, Hanabi.</div>
            </div>
            <div className='project-links'>
                <div><a target='_blank' href="https://arcoop.github.io/Bang/">Live Link</a></div>
                <div><a target='_blank' href="https://github.com/arcoop/Bang">Github</a></div>
            </div>
        </div>

        <div className='project'>
            <div className='project-first-div'>
                <a target="_blank" href='https://cubberd.herokuapp.com/main' className='project-image cubberd'></a>
                <div className='technologies'>
                    <p>MongoDB</p>
                    <div className='separator'><i className="fa-solid fa-circle"></i></div>
                    <p>Express</p>
                    <div className='separator'><i className="fa-solid fa-circle"></i></div>
                    <p>React.js</p>
                    <div className='separator'><i className="fa-solid fa-circle"></i></div>
                    <p>Node.js</p>

                </div>
            </div>
            <div className='project-caption'>
                <div>An interactive recipe planning app that searches for and scores recipes based on user input.</div>
            </div>
            <div className='project-links'>
                <div><a target='_blank' href="https://cubberd.herokuapp.com/main">Live Link</a></div>
                <div><a target='_blank' href="https://github.com/arcoop/Cubberd">Github</a></div>
            </div>
        </div>
    
     </div>
    )

}
export default ProjectsComponent;