import './PolaroidBig.css'
import Pin from '../Pin/Pin';
import { DarkModeContext } from '../../Context/DarkMode';
import { useContext } from 'react';

const PolaroidBig = () => {
    const {darkMode} = useContext(DarkModeContext)

    return (
        <div className={`big-main-background ${darkMode ? "dark" : "light"}`}>
            <Pin caption={"big"}/>
            <div className={`big-polaroid-image`}></div>
            <div className={`big-polaroid-caption ${darkMode ? "dark" : "light"}`}>
                <div className={`big-caption`}>
                    <p>Software Engineer</p>
                    <div className='polaroid-big-separator'><i className="fa-solid fa-circle"></i></div>
                    <p>Puzzler</p>
                    <div className='polaroid-big-separator'><i className="fa-solid fa-circle"></i></div>
                    <p>Board Games Enthusiast</p>

                </div>
            </div>
        </div>
    )

}
export default PolaroidBig;