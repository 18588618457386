import './MyProjects.css'
import StickyNote from '../StickyNote/StickyNote';

const MyProjects = ({closeModal}) => {
    return (
        <div className='projects-container'>
            <div className='projects-sticky'>
                <div onClick={closeModal} className='exit-modal'><i className="fa-solid fa-x"></i></div>
                <StickyNote type={"projects"} />
                <div className='projects-sticky-tape'></div>
            </div>

        </div>
    )
}

export default MyProjects;