import './AboutMe.css'
import StickyNote from '../StickyNote/StickyNote';

const AboutMe = ({closeModal}) => {
    return (
        <div className='about-me-container'>
            <div className='about-me-sticky'>
                <div onClick={closeModal} className='exit-modal'><i className="fa-solid fa-x"></i></div>
                <StickyNote type={"about-me"} />
                <div className='sticky-tape'>
                </div>
            </div>
        </div>
    )
}

export default AboutMe;