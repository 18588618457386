import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { DarkModeProvider } from './Context/DarkMode'
import {ModalProvider} from './Context/Modal'


function Root() {
  return (
    <DarkModeProvider>
      <ModalProvider>
        <App />
      </ModalProvider>
    </DarkModeProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);
