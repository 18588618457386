import { useEffect } from "react";
import {useContext, createContext, useRef, useState } from "react"
import ReactDOM from "react-dom";
import './Modal.css'

const ModalContext = createContext();

export const ModalProvider = ({...props}) => {
    const [value, setValue] = useState("")
    const modalRef = useRef()

    useEffect(() => {
        setValue(modalRef.current)
    }, [])

    return (
        <>
            <ModalContext.Provider value={value} >
                {props.children}
            </ModalContext.Provider>
            <div ref={modalRef} />
        </>
    );
}

export const Modal = ({onClose, type, children}) => {
    const modalNode = useContext(ModalContext)
    if (!modalNode) return null
    
    //(type === "signup" ? "modal-content-signup" : "modal-content-login")

    let divId;
    if (type === "about-me") divId = "modal-about-me"
    if (type === "my-projects" || type === "my-projects-icon") divId = "modal-projects"
    if (type === "email") divId = "modal-email"
    if (type === "view-more" || type === "view-more-icon") divId = "modal-view-more"

    return ReactDOM.createPortal(
        <div id="modal">
            <div className={`modal-background ${divId}`}
                onClick={onClose}>
            </div>

            <div id={divId}>
                {children}
            </div>

        </div>,
        modalNode
    )

}
