import './Polaroid.css'
import ModalPopUp from '../Modals/Index';
import { useState } from 'react';
import Resume from '../../assets/AdinaCooper.pdf'
import { useContext } from 'react';
import { DarkModeContext } from '../../Context/DarkMode';


const Polaroid = ({caption}) => {

    const {darkMode} = useContext(DarkModeContext)

    const [emailIcon, setEmailIcon] = useState(<i className="fa-regular fa-envelope"></i>)
    const [resumeIcon, setResumeIcon] = useState(<i className="fa-regular fa-file"></i>)
    const [folderIcon, setFolderIcon] = useState(<i className="fa-solid fa-folder-closed"></i>)

    let icon;
    let captionAction;
    if (caption === "email-me") {
        icon = <a target="_blank" href="mailto:cooperadina@gmail.com?subject='Love your work!'&body='Really enjoyed looking at your website. Would love to set up a time to talk.'">{emailIcon}</a>
        captionAction = <a target="_blank" href="mailto:cooperadina@gmail.com?subject='Love your work!'&body='Really enjoyed looking at your website. Would love to set up a time to talk.'">Email me</a>
    }
    if (caption === "my-projects") {
        captionAction = <ModalPopUp type={caption}/>
        icon = folderIcon
    }
    
    if (caption === "view-more") {
        captionAction = <ModalPopUp type={caption}/>
        icon= <ModalPopUp type={`${caption}-icon`}/>
    }

    if (caption === "resume") {
        captionAction = <a className='resume-caption noselect' target="_blank" href={Resume}>Resume</a>
        icon= <a target="_blank" href={Resume}>{resumeIcon}</a>
    }

    const handleHover = () => {
        if (caption === "email-me") setEmailIcon(<i className="fa-regular fa-envelope-open"></i>)
        if (caption === "resume") setResumeIcon(<i id='resume-hover' className="fa-solid fa-file-export"></i>)
        if (caption === "my-projects") setFolderIcon(<ModalPopUp type={`${caption}-icon`} />)
    }
    
    const handleUnhover = () => {
        if (caption === "email-me") setEmailIcon(<i className="fa-regular fa-envelope"></i>)
        if (caption === "resume") setResumeIcon(<i className="fa-regular fa-file"></i>)
        if (caption === "my-projects") setFolderIcon(<i className="fa-solid fa-folder-closed"></i>)
    }

    // const handleResumeHover = () => {
    // }

    // const handleResumeUnhover = () => {
    // }

    return (
        <div className={`main-background ${caption} ${darkMode ? "dark" : "light"}`}>
            <div className={`circle ${caption}`}></div>
            <div className={`line ${caption}`}></div>
            <div onMouseLeave={handleUnhover}  onMouseEnter={handleHover} className={`polaroid-image ${caption} ${darkMode ? "dark" : "light"}`}>
                {icon}
            </div>
            <div className={`polaroid-caption ${caption} ${darkMode ? "dark" : "light"}`}>
                {captionAction}
            </div>
        </div>
    )

}
export default Polaroid;