import { useState } from 'react'
import './StickyNote.css'
import Pin from '../Pin/Pin'
import AboutMeComponent from '../AboutMeComponent/AboutMeComponent'
import ViewMoreComponent from '../ViewMoreComponent/ViewMore'
import ProjectsComponent from '../ProjectsComponent/ProjectsComponent'
import ContactMe from '../ContactMeComponent/ContactMe' 

const StickyNote = ({type}) => {

    const [hovered, setHovered] = useState(false)

    let stickyCaption;
    if (type === "intro") stickyCaption = "Hi, I'm Adina!"
    if (type === "about-me") stickyCaption = <AboutMeComponent />
    if (type === "view-more") stickyCaption = <ViewMoreComponent />
    if (type === "projects") stickyCaption = <ProjectsComponent />
    const contactMe = <ContactMe />

    return (
        <div onMouseLeave={() => setHovered(false)} onMouseEnter={() => setHovered(true)} className='sticky-background'>
           <div className={`sticky top ${type} `}>
                {type === "intro" ? <Pin caption={"sticky-note"} /> : <></>}
                <p className={`sticky-caption ${type}`}>{stickyCaption}</p>
            </div>
           <div className={`sticky bottom ${type}`}>
                <div className={`sticky bottom left ${type}`}>
                    {type === "about-me" ? contactMe : <></>}
                </div>
                <div className={hovered ? `sticky bottom right hov ${type}` : `sticky bottom right ${type}` }></div>
           </div>
        </div>
    )
}

export default StickyNote;