const ViewMoreComponent = () => {
    return (
        <div className='view-more-links'>
            <a className='view-more-link' id='linked-link' target="_blank" href="https://www.linkedin.com/in/adina-cooper/"><i className="fa-brands fa-linkedin-in"></i></a>
            <a className='view-more-link' id='angel-link' target="_blank" href="https://angel.co/u/adina-cooper-1"><i className="fa-brands fa-angellist"></i></a>
            <a className='view-more-link' id='github-link' target="_blank" href="https://github.com/arcoop"><i className="fa-brands fa-github"></i></a>
        </div>
    )
}

export default ViewMoreComponent;