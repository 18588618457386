import './DarkModeToggle.css'
import { useEffect } from 'react';
import { useContext } from 'react';
import { DarkModeContext } from '../../Context/DarkMode';

const DarkModeToggle = () => {
    const {darkMode, setDarkMode} = useContext(DarkModeContext);

    const toggleDarkMode = () => {
        setDarkMode(darkMode ? false : true)
     }

    useEffect(() => {
        const body = document.getElementById("doc-body")
        body.setAttribute("class", darkMode ? "dark" : "light")
    }, [darkMode])

    const dark = <div className="dark-mode-button dark" onClick={toggleDarkMode}>
        <div><i id='sun-icon' className="fa-solid fa-sun"></i></div>
    </div>
    const light = <div className="dark-mode-button light" onClick={toggleDarkMode}>
        <div id='moon-icon'><i className="fa-solid fa-moon"></i></div>
    </div>

    return (
        <div className='dark-mode'>
            {darkMode ? dark : light}
        </div>
    )
}

export default DarkModeToggle;