import './MainPage.css'
import Polaroid from '../Polaroid/Polaroid';
import PolaroidBig from '../Polaroid/PolaroidBig';
import ModalPopUp from '../Modals/Index';
import { DarkModeContext } from '../../Context/DarkMode';
import { useContext } from 'react';
import DarkModeToggle from '../DarkModeToggleComponent/DarkModeToggle.js';

const MainPage = () => {
 
    return (
        <div className="main-page">
            <DarkModeToggle />
            <div className='cork-board-main'>
                <div className='cork-board-main-top'>
                    <ModalPopUp type={"about-me"}/>
                    <PolaroidBig/>
                </div>
                <div className='cork-board-main-bottom'>
                    <Polaroid caption={"view-more"}/>
                    <Polaroid  caption={"my-projects"}/>
                    <Polaroid caption={"email-me"}/>
                    <Polaroid caption={"resume"} />
                </div>
            </div>
        </div>
    )

}

export default MainPage;