import { useState } from 'react';
import './EmailMe.css'

const EmailMeForm = () => {
    const [subject, setSubject] = useState("Love your site! Let's connect.")
    const [body, setBody] = useState("I really enjoyed checking out youre site and would be interested in talking to you more about your work!")

    return (
        <div className='email-form-container'>
            <form>
                <div className='contact-subject'>
                    <label>Subject
                        <input type="text"
                            value={subject}
                            onChange={e => setSubject(e.target.value)}
                        />
                    </label>
                    <label> Message
                        <textarea 
                            value={body}
                            onChange={e => setBody(e.target.value)}
                        />
                    </label>
                </div>

            </form>
            <a target='_blank' href={`mailto:cooperadina@gmail.com?subject=${subject}&body=${body}`}>Send</a>
        </div>
    )
}

export default EmailMeForm;