const AboutMeComponent = () => {
    return (
        <div className='about-me-blurb'>
                <p>I am a former math educator turned non-profit manager turned full-stack software engineer.</p>
                <p>After teaching myself Python in my spare time (and loving it), I decided to pursue full-time software engineering.</p>
                <p>My skills and experiences include, but are not limited to:</p>
                <ul className='skills-list'>
                    <li>Javascript</li>
                    <li className='separator'><i className="fa-solid fa-circle"></i></li>
                    <li>React.js</li>
                    <li className='separator'><i className="fa-solid fa-circle"></i></li>
                    <li>Redux</li>
                    <li className='separator'><i className="fa-solid fa-circle"></i></li>
                    <li>Ruby</li>
                    <li className='separator'><i className="fa-solid fa-circle"></i></li>
                    <li>Rails</li>
                    <li className='separator'><i className="fa-solid fa-circle"></i></li>
                    <li>MongoDB</li>
                    <li className='separator'><i className="fa-solid fa-circle"></i></li>
                    <li>Python</li>
                    <li className='separator'><i className="fa-solid fa-circle"></i></li>
                    <li>AWS</li>
                </ul>
        </div>
    )
}

export default AboutMeComponent;