import './Pin.css'

const Pin = ({caption}) => {
    return (
        <div className={`pin-whole ${caption}`}>
            <div className={`circle-pin ${caption}`}></div>
            <div className={`line-pin ${caption}`}></div>
        </div>
    )
}

export default Pin;