import './ViewMore.css'
import StickyNote from '../StickyNote/StickyNote';

const ViewMore = ({closeModal}) => {
    return (
        <div className='view-more-container'>
            <div className='view-more-sticky'>
                <div onClick={closeModal} className='exit-modal'><i className="fa-solid fa-x"></i></div>
                <StickyNote type={"view-more"} />
                <div className='view-more-sticky-tape'></div>
            </div>
          
        </div>
    )
}

export default ViewMore;
